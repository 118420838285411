import { useState, useEffect, useCallback } from 'react';
import { getTransactionDetails } from '../api/services';

export const useTransactionDetails = (transactionId, token) => {
  const [transactionDetails, setTransactionDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTransactionDetails = useCallback(async () => {
    if (!transactionId || !token) {
      setError('Missing transaction ID or authentication token');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      
      const data = await getTransactionDetails(transactionId, token);
      setTransactionDetails(data);
    } catch (err) {
      console.error('Error fetching transaction details:', err);
      setError(err.message || 'Failed to load transaction details');
    } finally {
      setLoading(false);
    }
  }, [transactionId, token]);

  // Fetch details when transaction ID or token changes
  useEffect(() => {
    if (transactionId && token) {
      fetchTransactionDetails();
    }
  }, [transactionId, token, fetchTransactionDetails]);

  return {
    transactionDetails,
    loading,
    error,
    refreshTransactionDetails: fetchTransactionDetails
  };
};