// src/components/ui/LoginHero.jsx
import React from 'react';
import { useAuth } from "react-oidc-context";
import GreenButton from './GreenButton'; // Assuming you have a reusable Button component

const LoginHero = () => {
    const auth = useAuth();

    return (
        <div className="relative isolate px-6 pt-14 lg:px-8">
            <div className="mx-auto max-w-2xl py-8 sm:py-12 lg:py-14">
                <div className="text-center">
                    <h1 className="text-balance text-5xl font-semibold tracking-tight text-gray-900 sm:text-7xl">Please log in to continue</h1>
                    {/* <p className="mt-8 text-pretty text-lg font-medium text-gray-500 sm:text-xl/8">Please log in to continue.</p> */}
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <GreenButton onClick={() => auth.signinRedirect()}>
                            Log In
                        </GreenButton>
                        {/* <a href="#" className="text-sm/6 font-semibold text-gray-900">Learn more <span aria-hidden="true">→</span></a> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LoginHero;