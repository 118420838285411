import { useState, useEffect } from 'react';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.alignedinnovation.com/c3resource/v1';

export const useProcessingStatus = (jobId, token) => {
  const [status, setStatus] = useState({});
  const [isComplete, setIsComplete] = useState(false);
  const [error, setError] = useState(null);
  const [retry, setRetry] = useState(0); // State to trigger retry

  useEffect(() => {
    let isMounted = true;
    let intervalId;
    
    // If we don't have a jobId or token, don't try to fetch
    if (!jobId || !token) {
      return;
    }
    
    const fetchStatus = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/status/${jobId}`, {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        });

        if (!response.ok) {
          if (response.status === 403) {
            throw new Error('Access forbidden: Invalid token or insufficient permissions.');
          } else if (response.status === 404) {
            throw new Error('Job not found. It may have been removed or expired.');
          } else {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
        }

        const data = await response.json();
        if (isMounted) {
          setStatus(data);
          // Check if any of the statuses are not 'pending'
          const isPending = data.status === 'pending';
          setIsComplete(!isPending);
          
          // If not pending, clear the interval
          if (!isPending && intervalId) {
            clearInterval(intervalId);
          }
        }
      } catch (err) {
        if (isMounted) {
          setError(err.message);
          if (intervalId) {
            clearInterval(intervalId);
          }
        }
      }
    };

    // Initial fetch
    fetchStatus();
    
    // Set up polling every 2 seconds
    intervalId = setInterval(fetchStatus, 2000);

    return () => {
      isMounted = false;
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [jobId, token, retry]); // Add retry as a dependency

  const onRetry = () => {
    setRetry(prevRetry => prevRetry + 1); // Increment retry state to trigger useEffect
  };

  const disconnect = () => {
    // Add any necessary cleanup logic here
  };

  return { status, isComplete, error, disconnect, onRetry };
};