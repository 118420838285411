import CarbonTokenApp from './components/CarbonTokenApp';
import { useAuth } from "react-oidc-context";
import { Header } from '@/components/ui/Header';
import LoginHero from '@/components/ui/LoginHero';
// import { useParams, useLocation } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function App() {
  const auth = useAuth();
  // const { step } = useParams();
  const location = useLocation();
  
  // const signOutRedirect = () => {
  //   const clientId = "1m7k7d8a3otlavgqlcctt9agp4";
  //   const logoutUri = "<logout uri>";
  //   const cognitoDomain = "https://us-east-1nyqlyvaq4.auth.us-east-1.amazoncognito.com";
  //   window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
  // };


  // Extract transaction ID from URL query parameters
  const queryParams = new URLSearchParams(location.search);
  const transactionId = queryParams.get('transaction');

  return (
    <div>
      <Header />
      <div className="min-h-screen bg-gray-50 p-8">
        {auth.isLoading ? (
          // Loading State
          <div className="flex justify-center items-center h-full">
            <div className="text-xl">Loading...</div>
          </div>
        ) : auth.error ? (
          // Error State
          <div className="flex justify-center items-center h-full">
            <div className="text-red-500 text-lg">
              Encountering error... {auth.error.message}
            </div>
          </div>
        ) : !auth.isAuthenticated ? (
          <LoginHero />
        ) : (
          // Authenticated State - pass transaction ID to the app
          <CarbonTokenApp transactionId={transactionId} />
        )}
      </div>
    </div>
  );
}

export default App;
