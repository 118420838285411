export const FILE_TYPE_FIELDS = {
    '-- No Mappings --': {},
    'Facility Information': {
      facilityName: {
        description: 'Name of the facility',
        required: true,
        type: 'string'
      },
      location: {
        description: 'Geographic location',
        required: true,
        type: 'string'
      },
      operatorInfo: {
        description: 'Operator information',
        required: true,
        type: 'string'
      },
      capacity: {
        description: 'Processing capacity (tons/year)',
        required: true,
        type: 'number'
      }
    },
    'Transport Information': {
      pipelineId: {
        description: 'Unique pipeline identifier',
        required: true,
        type: 'string'
      },
      startPoint: {
        description: 'Pipeline start location',
        required: true,
        type: 'string'
      },
      endPoint: {
        description: 'Pipeline end location',
        required: true,
        type: 'string'
      },
      flowRate: {
        description: 'CO2 flow rate (tons/day)',
        required: true,
        type: 'number'
      }
    },
    'Well Information': {
      wellId: {
        description: 'Unique well identifier',
        required: true,
        type: 'string'
      },
      depth: {
        description: 'Well depth (meters)',
        required: true,
        type: 'number'
      },
      formation: {
        description: 'Geological formation',
        required: true,
        type: 'string'
      },
      injectionRate: {
        description: 'CO2 injection rate (tons/day)',
        required: false,
        type: 'number'
      }
    },
    'CO2 Monitoring': {
      timestamp: {
        description: 'Measurement timestamp',
        required: true,
        type: 'date'
      },
      pressure: {
        description: 'Reservoir pressure (PSI)',
        required: true,
        type: 'number'
      },
      temperature: {
        description: 'Temperature (°C)',
        required: true,
        type: 'number'
      },
      co2Concentration: {
        description: 'CO2 concentration (%)',
        required: false,
        type: 'number'
      }
    }
  };