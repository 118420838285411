import React, { useState, useEffect, useMemo } from 'react';
import { Upload, FileUp, AlertCircle, Check, X, RefreshCcw } from 'lucide-react';
import { useAuth } from "react-oidc-context";
import { useNavigate, useLocation } from 'react-router-dom';

import { Alert, AlertDescription } from '@/components/ui/alert';
import { Card, CardContent, CardHeader } from '@/components/ui/card';

// Import API services and hooks
import { uploadFiles, sendFieldMappings } from '../api/services';

// Import components
import FileUpload from './steps/FileUpload';
import FieldMapping from './steps/FieldMapping';
import ProcessingStatus from './steps/ProcessingStatus';
import Results from './steps/Results';

const CarbonTokenApp = ({ transactionId }) => {
  const navigate = useNavigate();
  const location = useLocation();
  
  // Initialize state from session storage if available
  const getInitialState = () => {
    try {
      const savedState = sessionStorage.getItem('carbonTokenAppState');
      if (savedState) {
        return JSON.parse(savedState);
      }
    } catch (e) {
      console.error('Error loading state from session storage:', e);
    }
    // Default state if nothing in session storage
    return {
      currentStep: 1,
      completedSteps: [],
      selectedFiles: [],
      uploadedKeys: [],
      jobId: null,
      processingStatus: {
        ipfs: 'pending',
        blockchain: 'pending',
        tokens: 'pending',
        ipfsURI: null,
      }
    };
  };

  // Extract initial state
  const initialState = getInitialState();
  
  // Parse current step from URL path
  const getStepFromUrl = () => {
    const pathParts = location.pathname.split('/');
    const stepParam = pathParts[pathParts.length - 1];
    if (['data', 'map', 'process', 'results'].includes(stepParam)) {
      const stepMap = { 'data': 1, 'map': 2, 'process': 3, 'results': 4 };
      return stepMap[stepParam];
    }
    return initialState.currentStep;
  };

  // If we have a transaction ID, force step 4 (Results)
  const initialStep = transactionId ? 4 : getStepFromUrl();

  // States
  const [currentStep, setCurrentStep] = useState(initialStep);
  const [error, setError] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState(initialState.selectedFiles);
  // Keep the uploadedKeys state but we won't need to set it directly
  const [uploadedKeys] = useState(initialState.uploadedKeys);
  const [jobId, setJobId] = useState(initialState.jobId);
  const [processingStatus, setProcessingStatus] = useState(initialState.processingStatus);
  // Track completed steps to know which ones can be navigated to
  const [completedSteps, setCompletedSteps] = useState(initialState.completedSteps);
  // Store the transaction ID for results page
  const [selectedTransaction, setSelectedTransaction] = useState(transactionId);
  
  // Map steps to URL paths using useMemo to avoid recreating on every render
  const stepToPath = useMemo(() => ({
    1: 'data',
    2: 'map',
    3: 'process',
    4: 'results'
  }), []);

  const auth = useAuth();

  // If we have a transaction ID, update the state with it
  useEffect(() => {
    if (transactionId) {
      setSelectedTransaction(transactionId);
      setCurrentStep(4);
      // Simulate completed steps
      if (completedSteps.length < 3) {
        setCompletedSteps([1, 2, 3]);
      }
    }
  }, [transactionId, completedSteps.length]);

  const steps = [
    { number: 1, title: 'Provide Data', icon: FileUp },
    { number: 2, title: 'Map Fields', icon: Upload },
    { number: 3, title: 'Processing', icon: AlertCircle },
    { number: 4, title: 'Results', icon: Check }
  ];

  // Update session storage when state changes
  useEffect(() => {
    try {
      // We need to include uploadedKeys in session storage even though we don't set it directly
      const stateToSave = {
        currentStep,
        completedSteps,
        selectedFiles,
        uploadedKeys,
        jobId,
        processingStatus,
        selectedTransaction
      };
      
      // Need to clean the selectedFiles array as File objects can't be serialized
      const serializableState = {
        ...stateToSave,
        selectedFiles: selectedFiles.map(file => ({
          name: file.name,
          size: file.size,
          type: file.type,
          lastModified: file.lastModified
        }))
      };
      
      sessionStorage.setItem('carbonTokenAppState', JSON.stringify(serializableState));
    } catch (e) {
      console.error('Error saving state to session storage:', e);
    }
  }, [currentStep, completedSteps, selectedFiles, uploadedKeys, jobId, processingStatus, selectedTransaction]);

  // Update URL when step changes
  useEffect(() => {
    const path = stepToPath[currentStep];
    if (path) {
      // If we're going to results and have a transaction ID, include it in the URL
      if (path === 'results' && selectedTransaction) {
        navigate(`/carbon-tokens/${path}?transaction=${selectedTransaction}`, { replace: true });
      } else {
        navigate(`/carbon-tokens/${path}`, { replace: true });
      }
    }
  }, [currentStep, navigate, selectedTransaction, stepToPath]);

  // Handler for step navigation
  const handleStepClick = (stepNumber) => {
    // Only allow navigation to steps that are completed or the current step
    if (completedSteps.includes(stepNumber) || stepNumber === currentStep) {
      setCurrentStep(stepNumber);
    }
  };

  const handleFileUpload = async (files) => {
    if (files.some(file => file.size > 5 * 1024 * 1024)) {
      setError({
        step: 1,
        message: 'One or more files exceed the 5MB size limit.',
        type: 'error'
      });
      return;
    }

    try {
      setSelectedFiles(files);
      setError(null);
      setCurrentStep(2);
      // Mark this step as completed
      if (!completedSteps.includes(1)) {
        setCompletedSteps([...completedSteps, 1]);
      }
    } catch (err) {
      setError({
        step: 1,
        message: err.message || 'Error uploading files. Please try again.',
        type: 'error'
      });
    }
  };

  const handleFieldMapping = async (mappings) => {
    try {
      // Create processing job with uploaded files and mappings
      const jobRequest = await sendFieldMappings(mappings, auth.user?.id_token);
      const jobId = jobRequest?.batch;  // get batch ID

      // response has the s3 URLs per file; parse them to create a mapping of file
      // to attributes for uploadFiles
      const fileMappings = Object.entries(jobRequest.files).map(([fileName, attrs]) => {
        const [fileObj] = selectedFiles.filter(f => f.name === fileName);
        return {name: fileName, url: attrs.url, id: attrs.id, file: fileObj};
      });

      // Upload files using the mappings
      await uploadFiles(fileMappings, (fileName, progress) => {
        console.log(`Uploading ${fileName}: ${progress}%`);
      });
      
      setJobId(jobId);
      setError(null);
      setCurrentStep(3);
      // Mark this step as completed
      if (!completedSteps.includes(2)) {
        setCompletedSteps([...completedSteps, 2]);
      }
    } catch (err) {
      setError({
        step: 2,
        message: err.message || 'Error processing files. Please try again.',
        type: 'error'
      });
    }
  };

  const handleProcessingAction = (action) => {
    if (action === 'error') {
      setError({
        step: 3,
        message: 'Processing timeout. Please try again.',
        type: 'error'
      });
    } else if (action === 'retry') {
      setError(null);
      setCurrentStep(4);
      setProcessingStatus({
        ipfs: 'complete',
        blockchain: 'complete',
        tokens: 'complete'
      });
      // Mark this step as completed
      if (!completedSteps.includes(3)) {
        setCompletedSteps([...completedSteps, 3]);
      }
    }
  };
  
  // Handle transaction selection from ProcessingStatus 
  const handleTransactionSelect = (transactionId) => {
    console.log('Setting selected transaction:', transactionId);
    setSelectedTransaction(transactionId);
    
    // Mark steps as completed if needed
    const updatedCompletedSteps = [...completedSteps];
    [1, 2, 3].forEach(step => {
      if (!updatedCompletedSteps.includes(step)) {
        updatedCompletedSteps.push(step);
      }
    });
    setCompletedSteps(updatedCompletedSteps);
    
    // Navigate to results step
    setCurrentStep(4);
    
    // Update URL directly for immediate feedback
    navigate(`/carbon-tokens/results?transaction=${transactionId}`, { replace: true });
  };

  const ErrorAlert = ({ message, onRetry }) => (
    <Alert className="bg-red-50 border-red-200 mb-4">
      <X className="h-4 w-4 text-red-600" />
      <AlertDescription className="text-red-800 flex items-center justify-between">
        <span>{message}</span>
        {onRetry && (
          <button
            onClick={onRetry}
            className="flex items-center text-sm text-red-600 hover:text-red-700"
          >
            <RefreshCcw className="h-4 w-4 mr-1" />
            Retry
          </button>
        )}
      </AlertDescription>
    </Alert>
  );

  return (
    <div className="max-w-4xl mx-auto">
      {/* Header */}
      <div className="mb-8">
        <h1 className="text-2xl md:text-3xl font-bold text-gray-900 mb-2">Carbon Token Minting Portal</h1>
        <p className="text-gray-600">Upload your data to mint carbon tokens</p>
      </div>

      {/* Progress Steps */}
      <div className="flex justify-between mb-8">
        {steps.map((step) => {
          const StepIcon = step.icon;
          const isError = error?.step === step.number;
          const isCompleted = completedSteps.includes(step.number);
          const isCurrent = currentStep === step.number;
          
          // Determine if this step is clickable
          const isClickable = isCompleted || isCurrent;
          
          return (
            <div
              key={step.number}
              className={`flex flex-col items-center w-1/4 ${
                isError ? 'text-red-600' :
                isCurrent ? 'text-blue-600' :
                isCompleted ? 'text-green-600' : 'text-gray-400'
              } ${isClickable ? 'cursor-pointer' : 'cursor-not-allowed'}`}
              onClick={() => isClickable && handleStepClick(step.number)}
            >
              <div className={`w-10 h-10 rounded-full flex items-center justify-center mb-2 ${
                isError ? 'bg-red-100 border-2 border-red-600' :
                isCurrent ? 'bg-blue-100 border-2 border-blue-600' :
                isCompleted ? 'bg-green-100 border-2 border-green-600' : 'bg-gray-100 border-2 border-gray-400'
              }`}>
                {isError ? <X className="w-5 h-5" /> : <StepIcon className="w-5 h-5" />}
              </div>
              <span className="text-sm font-medium">{step.title}</span>
            </div>
          );
        })}
      </div>

      {/* Main Content Area */}
      <Card className="mb-8">
        <CardHeader>
        </CardHeader>
        <CardContent>
          {error && <ErrorAlert message={error.message} onRetry={currentStep === 3 ? () => handleProcessingAction('retry') : null} />}

          {currentStep === 1 && (
            <FileUpload onFileSelect={handleFileUpload} error={error?.step === 1} />
          )}

          {currentStep === 2 && (
            <FieldMapping
              files={selectedFiles}
              uploads={uploadedKeys}
              onConfirm={handleFieldMapping}
              error={error?.step === 2}
            />
          )}

          {currentStep === 3 && (
            <ProcessingStatus
              jobId={jobId}
              token={auth.user?.id_token || ''}
              status={processingStatus}
              error={error?.step === 3}
              onRetry={handleProcessingAction}
              onComplete={(details) => {
                const newURI = details.ipfs?.ipfsuri;
                setProcessingStatus({
                  ipfs: 'complete',
                  blockchain: 'complete',
                  tokens: 'complete',
                  ipfsURI: (newURI) ? newURI : processingStatus.ipfsURI
                });
                // Mark this step as completed
                if (!completedSteps.includes(3)) {
                  setCompletedSteps([...completedSteps, 3]);
                }
              }}
              onTransactionSelect={(transactionId) => {
                console.log('Transaction selected in CarbonTokenApp:', transactionId);
                handleTransactionSelect(transactionId);
              }}
            />
          )}

          {currentStep === 4 && (
            <Results 
              transactionId={selectedTransaction}
              token={auth.user?.id_token || ''}
            />
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default CarbonTokenApp;