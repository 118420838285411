// API base URLs - these would come from environment variables in a real app
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.alignedinnovation.com/c3resource/v1';

// Utility function to handle API errors
const handleApiError = (error) => {
  if (error.response) {
    console.error('API Error:', error.response.data);
    throw new Error(error.response.data.message || 'API request failed');
  }
  console.error('Network Error:', error);
  throw new Error('Network error occurred');
};

// File Upload Service
export const uploadFiles = async (files, onProgress) => {
  /*
  [{
    "name": "co2_monitoring.csv",
    "url": "https://...",
    "id": "0e41bdff-0557-4fe1-9c0d-779d0fb893e6",
    "file": {File}
  }]
  */
  try {
    // Upload files in parallel with progress tracking
    const uploadPromises = files.map(async (file) => {
      const xhr = new XMLHttpRequest();

      // Track individual file progress
      xhr.upload.onprogress = (event) => {
        if (event.lengthComputable) {
          const percentComplete = (event.loaded / event.total) * 100;
          onProgress(file.name, percentComplete);
        }
      };

      return new Promise((resolve, reject) => {
        xhr.open('PUT', file.url);
        xhr.onload = () => resolve([file.name, file.id]);
        xhr.onerror = () => reject(new Error('Upload failed'));
        xhr.send(file.file);
      });
    });

    const uploadedKeys = await Promise.all(uploadPromises);
    return uploadedKeys;
  } catch (error) {
    handleApiError(error);
  }
};

// Header Mapping Service
export const submitHeaderMappings = async (mappings) => {
  try {
    const response = await fetch(`${API_BASE_URL}/mappings`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(mappings)
    });

    if (!response.ok) throw new Error('Failed to submit mappings');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// Processing Status Service
export const getProcessingStatus = async (jobId, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/status/${jobId}`, {
      headers: {
        'Authorization': token
      }
    });
    if (!response.ok) throw new Error('Failed to get status');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// IPFS Progress Service
export const getIpfsProgress = async (jobId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/ipfs/status/${jobId}`);
    if (!response.ok) throw new Error('Failed to get IPFS status');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// MassCertificate Storage Service
export const getMassCertificateStatus = async (jobId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/masscert/status/${jobId}`);
    if (!response.ok) throw new Error('Failed to get MassCertificate status');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// Token Minting Service
export const mintTokens = async (type, amount, recipientAddress, token) => {
  try {
    if (!token) {
      throw new Error('Authentication token is required');
    }
    
    // SECURITY TODO: recipientAddress cannot be trusted
    const response = await fetch(`${API_BASE_URL}/tokens/mint`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        tokenType: type, // 'carbon', 'vcm', or '45q'
        amount,
        recipientAddress
      })
    });

    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        throw new Error('Authentication failed. Please log in again.');
      } else if (response.status === 422) {
        throw new Error('Invalid input. Please check your values and try again.');
      } else {
        throw new Error('Failed to mint tokens');
      }
    }
    
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// Form Generation Service
export const generate8933Form = async (tokenData, token) => {
  try {
    if (!token) {
      throw new Error('Authentication token is required');
    }
    
    const response = await fetch(`${API_BASE_URL}/forms/8933`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify(tokenData)
    });

    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        throw new Error('Authentication failed. Please log in again.');
      } else if (response.status === 422) {
        throw new Error('Invalid form data. Please check your values and try again.');
      } else {
        throw new Error('Failed to generate form');
      }
    }

    // Return blob for PDF download
    const blob = await response.blob();
    return blob;
  } catch (error) {
    handleApiError(error);
  }
};

// WebSocket Service for Real-time Updates
export class ProcessingSocket {
  constructor(jobId, onUpdate, onError) {
    this.ws = new WebSocket(`${API_BASE_URL.replace('http', 'ws')}/ws/status/${jobId}`);

    this.ws.onopen = () => {
      console.log('WebSocket connection established');
    };

    this.ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      onUpdate(data);
    };

    this.ws.onerror = (error) => {
      console.error('WebSocket error:', error);
      if (onError) onError(error);
    };

    this.ws.onclose = () => {
      console.log('WebSocket connection closed');
    };
  }

  disconnect() {
    if (this.ws && this.ws.readyState === WebSocket.OPEN) {
      this.ws.close();
    }
  }
}

// Processing Job Creation Service
export const sendFieldMappings = async (mappings, token) => {
  try {
    const response = await fetch(`${API_BASE_URL}/mappings`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': token,
      },
      body: JSON.stringify({ files: mappings })
    });

    if (!response.ok) {
      console.error(`failed to set mappings: ${response.statusText}`);
      throw new Error('Failed to set mappings');
    }

    return await response.json();
  } catch (error) {
    console.error(`failed to set mappings`, error);
    handleApiError(error);
  }
};

// Processing Job Creation Service
export const createProcessingJob = async (fileKeys, mappings) => {
  try {
    const response = await fetch(`${API_BASE_URL}/jobs`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fileKeys,
        mappings
      })
    });

    if (!response.ok) throw new Error('Failed to create processing job');
    return response.json();
  } catch (error) {
    handleApiError(error);
  }
};

// Get Transactions Service
export const getTransactions = async (token) => {
  try {
    if (!token) {
      throw new Error('No authentication token provided');
    }
    
    const response = await fetch(`${API_BASE_URL}/transactions`, {
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        console.error('Authentication error when fetching transactions');
        throw new Error('Authentication failed. Please log in again.');
      } else if (response.status === 404) {
        console.error('Transactions endpoint not found');
        throw new Error('Transactions service unavailable');
      } else {
        console.error(`Failed to fetch transactions: ${response.statusText}`);
        throw new Error('Failed to fetch transactions');
      }
    }
    
    const data = await response.json();
    
    // Validate the response structure
    if (!data || !Array.isArray(data.transactions)) {
      console.error('Invalid transaction data format:', data);
      throw new Error('Invalid transaction data format received');
    }
    
    return data;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    // Re-throw the error to be handled by the hook
    throw error;
  }
};

// Get Transaction Details Service
export const getTransactionDetails = async (transactionId, token) => {
  try {
    if (!token) {
      throw new Error('No authentication token provided');
    }
    
    if (!transactionId) {
      throw new Error('No transaction ID provided');
    }
    
    const response = await fetch(`${API_BASE_URL}/transactions/${transactionId}`, {
      headers: {
        'Authorization': token,
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      if (response.status === 401 || response.status === 403) {
        console.error('Authentication error when fetching transaction details');
        throw new Error('Authentication failed. Please log in again.');
      } else if (response.status === 404) {
        console.error('Transaction not found');
        throw new Error('Transaction not found');
      } else {
        console.error(`Failed to fetch transaction details: ${response.statusText}`);
        throw new Error('Failed to fetch transaction details');
      }
    }
    
    return response.json();
  } catch (error) {
    console.error('Error fetching transaction details:', error);
    // Re-throw the error to be handled by the caller
    throw error;
  }
};