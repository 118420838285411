// import React, { useState, useEffect, useRef } from 'react';
import React, { useEffect, useRef } from 'react';
import { Check, X, ChevronRight, Clock, RefreshCw } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useProcessingStatus } from '../../hooks/useProcessingStatus';
import { useTransactions } from '../../hooks/useTransactions';
import { Card, CardHeader, CardTitle, CardContent } from '@/components/ui/card';
import { Alert, AlertDescription } from '@/components/ui/alert';

const ProcessingStatus = ({ jobId, token, error: externalError, onComplete, onRetry, onTransactionSelect }) => {
  const navigate = useNavigate();
  const { status, error: processingError, isComplete, disconnect, onRetry: retryProcessing } = useProcessingStatus(jobId, token);
  const { 
    transactions, 
    loading: transactionsLoading, 
    error: transactionsError, 
    lastUpdated,
    refreshTransactions 
  } = useTransactions(token);
  
  // Use ref to track completion state and avoid repeated effects
  const completionProcessedRef = useRef(false);

  // We no longer need this effect as the useTransactions hook handles it

  // Handle completion only once
  useEffect(() => {
    if (isComplete && !completionProcessedRef.current) {
      completionProcessedRef.current = true;
      onComplete(status);
      
      // Refresh transactions when processing completes
      refreshTransactions();
    }
    
    return () => {
      if (typeof disconnect === 'function') {
        disconnect();
      }
    };
  }, [isComplete, status, onComplete, disconnect, refreshTransactions]);

  const handleRetry = () => {
    retryProcessing();
    if (onRetry) onRetry();
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', { 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
  };

  const handleTransactionClick = (transactionId) => {
    // Log for debugging
    console.log('Transaction clicked:', transactionId);
    
    // Call the parent component's handler if available
    if (typeof onTransactionSelect === 'function') {
      onTransactionSelect(transactionId);
    } else {
      // Fallback to direct navigation
      navigate(`/carbon-tokens/results?transaction=${transactionId}`);
    }
  };

  // Add the newest transaction if processing just completed
  const displayTransactions = isComplete && !transactions.find(t => t.jobId === jobId) && status?.mint?.progress === 100
    ? [
        // Add newly completed transaction if it's not in the list yet
        { 
          id: `0x${Math.random().toString(16).substring(2)}${Math.random().toString(16).substring(2)}`,
          timestamp: new Date().toISOString(),
          volume: Math.floor(Math.random() * 500) + 100,
          jobId: jobId,
          status: 'complete'
        },
        ...transactions
      ] 
    : transactions;

  const StatusIndicator = ({ type, label }) => (
    <div className="flex items-center space-x-4">
      <div className="w-32 md:w-40">
        <span className="text-sm font-medium text-gray-700">{label}</span>
      </div>
      <div className="flex-1">
        {externalError || processingError ? (
          <div className="flex items-center">
            <X className="text-red-600 h-5 w-5" />
            <span className="ml-2 text-sm text-red-600">
              {processingError || 'Processing failed'}
            </span>
          </div>
        ) : status[type] && status[type].progress === 100 ? (
          <div className="flex items-center">
            <Check className="text-green-600 h-5 w-5" />
            <span className="ml-2 text-sm text-green-600">Complete</span>
          </div>
        ) : (
          <div className="relative w-full">
            <div className="h-2 bg-gray-200 rounded-full w-full">
              <div 
                className="h-full bg-blue-600 rounded-full transition-all duration-100"
                style={{ width: `${status[type] ? status[type].progress : 0}%` }}
              />
            </div>
            <span className="absolute right-0 top-2 text-xs text-gray-500">
              {status[type] ? Math.round(status[type].progress) : 0}%
            </span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <div className="space-y-8">
      {/* Status indicators section */}
      {isComplete ? (
        <Alert className="bg-green-50 border-green-200 p-4">
          <div className="flex items-center">
            <Check className="h-6 w-6 text-green-600 mr-3" />
            <AlertDescription className="text-green-800 text-lg font-medium">
              Data upload complete
            </AlertDescription>
          </div>
        </Alert>
      ) : (
        <div className="space-y-6">
          <StatusIndicator 
            type="files"
            label="Processing Files"
          />
          <StatusIndicator 
            type="ipfs"
            label="Uploading to IPFS"
          />
          <StatusIndicator 
            type="mint"
            label="Storing in MassCertificate"
          />
        </div>
      )}
      
      {/* Retry button for errors */}
      {(externalError || processingError) && !isComplete && (
        <button onClick={handleRetry}
          className="w-full px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition-colors"
        >
          Retry Processing
        </button>
      )}

      {/* Transactions list section */}
      <Card className="mt-8">
        <CardHeader className="pb-3">
          <div className="flex justify-between items-center">
            <CardTitle className="text-lg font-medium">MassCertificate Transactions</CardTitle>
            <button 
              onClick={refreshTransactions}
              disabled={transactionsLoading}
              className="p-1.5 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500"
              title="Refresh transactions"
            >
              <RefreshCw className={`h-4 w-4 text-gray-500 ${transactionsLoading ? 'animate-spin' : ''}`} />
            </button>
          </div>
          {lastUpdated && (
            <div className="text-xs text-gray-500 mt-1">
              Last updated: {lastUpdated.toLocaleTimeString()}
            </div>
          )}
        </CardHeader>
        <CardContent>
          <div className="overflow-hidden">
            {transactionsLoading && displayTransactions.length === 0 ? (
              <div className="py-4 text-center text-gray-500">
                Loading transactions...
              </div>
            ) : transactionsError ? (
              <div className="py-4 text-center text-red-500">
                {transactionsError}
                <button 
                  onClick={refreshTransactions}
                  className="ml-2 text-blue-500 hover:text-blue-700 underline"
                >
                  Retry
                </button>
              </div>
            ) : displayTransactions.length === 0 ? (
              <div className="py-4 text-center text-gray-500">
                No transactions found
              </div>
            ) : (
              <div className="space-y-2">
                {displayTransactions.map((transaction, index) => (
                  <button 
                    key={`${transaction.id}-${index}`}
                    onClick={() => handleTransactionClick(transaction.id)}
                    className="w-full text-left flex items-center justify-between p-3 border border-gray-200 rounded-lg hover:bg-gray-50 cursor-pointer transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <div className="flex items-center space-x-3">
                      <Clock className="h-5 w-5 text-blue-500" />
                      <div>
                        <div className="text-sm font-medium text-gray-900 truncate max-w-[300px]">
                          {transaction.id}
                        </div>
                        <div className="text-xs text-gray-500">
                          {formatDate(transaction.timestamp)}
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center space-x-4">
                      <div className="text-sm font-medium text-green-600">
                        {transaction.co2volume || transaction.volume || 0} tons CO<sub>2</sub>
                      </div>
                      <ChevronRight className="h-4 w-4 text-gray-400" />
                    </div>
                  </button>
                ))}
              </div>
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default ProcessingStatus;