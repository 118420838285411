import React, { useState, useEffect, useRef } from 'react';
import { ExternalLink, Clock, Coins, FileCheck, RefreshCw } from 'lucide-react';
import { useTokenMinting } from '../../hooks/useTokenMinting';
import { useTransactionDetails } from '../../hooks/useTransactionDetails';

// Existing helper functions remain the same
const formatTimeRemaining = (seconds) => {
  if (seconds < 60) return `${seconds} seconds`;
  
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;
 
  if (hours > 0) {
    return `${hours} hour${hours > 1 ? 's' : ''}, ${minutes} minute${minutes > 1 ? 's' : ''}, ${remainingSeconds} seconds`;
  }
  
  return `${minutes} minute${minutes > 1 ? 's' : ''}, ${remainingSeconds} seconds`;
};

// const formatUsdFee = (networkFee) => {
//   const usdValue = networkFee * 0.000000000688354;
//   return usdValue.toFixed(6);
// };

const TruncatedAddress = ({ address, maxWidth = "300px" }) => {
  const truncate = (str) => {
    const start = str.slice(0, 7);
    const end = str.slice(-5);
    return `${start}...${end}`;
  };

  return (
    <div className={"truncate max-w-["+maxWidth+"]"} title={address}>
      <a 
        href={`https://www.oklink.com/amoy/address/${address}`}
        target="_blank"
        rel="noopener noreferrer"
        className="text-blue-600 hover:text-blue-700 flex items-center"
      >
        {truncate(address)}
        <ExternalLink className="h-3 w-3 ml-1 shrink-0" />
      </a>
    </div>
  );
};

// Function to shorten transaction ID
const shortenTransactionId = (id) => {
  if (!id || id.length <= 16) return id;
  return `${id.substring(0, 6)}...${id.substring(id.length - 7)}`;
};

const TransactionDetails = ({ 
  title, 
  data, 
  timeRemaining, 
  status, 
  tokenSymbol, 
  transactionHash 
}) => (
  <div 
    className="p-2 md:p-4 bg-gray-50 rounded-md animate-[fadeIn_0.5s_ease-out]"
    style={{
      '@keyframes fadeIn': {
        '0%': { opacity: 0, transform: 'translateY(10px)' },
        '100%': { opacity: 1, transform: 'translateY(0)' }
      }
    }}
  >
    <h3 className="font-medium">{title}</h3>
    <div className="grid grid-cols-2 gap-x-4 gap-y-3 text-sm">
      <dt className="text-gray-600">Tokens:</dt>
      <dd className="font-bold text-green-600 text-lg">{data.tokensMinted} {tokenSymbol}</dd>

      <dt className="text-gray-600">To:</dt>
      <dd>
        <TruncatedAddress address={data.recipientAddress} />
      </dd>

      <dt className="text-gray-600">Network:</dt>
      <dd>Polygon</dd>

      {/* <dt className="text-gray-600">Network fee:</dt>
      <dd>{data.networkFee} POL ({formatUsdFee(data.networkFee)} USD)</dd> */}

      <dt className="text-gray-600">Date:</dt>
      <dd>{data.transactionDate.toLocaleString()}</dd>

      <dt className="text-gray-600">Status:</dt>
      <dd className="flex items-center">
        {status === 'Complete' ? (
          <span className="flex h-2 w-2 rounded-full bg-green-500 mr-2"></span>
        ) : (
          <span className="flex h-2 w-2 rounded-full bg-yellow-400 mr-2"></span>
        )}
        {status}
      </dd>

      {timeRemaining > 0 ? (
        <>
          <dt className="text-gray-600">Time remaining:</dt>
          <dd className="flex items-center">
            <Clock className="h-4 w-4 text-gray-400 mr-1" />
            {formatTimeRemaining(timeRemaining)}
          </dd>
        </>
      ) : (
        <>
          <dt className="text-gray-600">Transaction:</dt>
          <dd>
            <a
              href={`https://www.oklink.com/amoy/tx/${transactionHash || ''}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 hover:text-blue-700 flex items-center"
            >
              View on block explorer
              <ExternalLink className="h-4 w-4 ml-1" />
            </a>
          </dd>
        </>
      )}
    </div>
  </div>
);

const Results = ({ transactionId, token }) => {
  const { mintingStatus, error: mintingError, mint, generateForm } = useTokenMinting(token);
  const { 
    transactionDetails, 
    loading: detailsLoading, 
    error: detailsError,
    refreshTransactionDetails 
  } = useTransactionDetails(transactionId, token);
  
  const [showTransaction, setShowTransaction] = useState(false);
  const [showVCMTransaction, setShowVCMTransaction] = useState(false);
  const [show45QTransaction, setShow45QTransaction] = useState(false);
  
  // Time remaining state values
  const [timeRemaining, setTimeRemaining] = useState(30);
  const [status, setStatus] = useState('Pending');
  const [timeRemaining45Q, setTimeRemaining45Q] = useState(15);
  const [status45Q, setStatus45Q] = useState('Pending');
  const [timeRemainingVCM, setTimeRemainingVCM] = useState(20);
  const [statusVCM, setStatusVCM] = useState('Pending');
  
  // Update timers based on minting status
  useEffect(() => {
    if (mintingStatus.carbon.status === 'complete') {
      setStatus('Complete');
      setTimeRemaining(0);
      setShowTransaction(true);
    } else if (mintingStatus.carbon.status === 'minting') {
      setShowTransaction(true);
    }
    
    if (mintingStatus.vcm.status === 'complete') {
      setStatusVCM('Complete');
      setTimeRemainingVCM(0);
      setShowVCMTransaction(true);
    } else if (mintingStatus.vcm.status === 'minting') {
      setShowVCMTransaction(true);
    }
    
    if (mintingStatus.q45.status === 'complete') {
      setStatus45Q('Complete');
      setTimeRemaining45Q(0);
      setShow45QTransaction(true);
    } else if (mintingStatus.q45.status === 'minting') {
      setShow45QTransaction(true);
    }
  }, [mintingStatus]);
  
  // Refs for scrolling
  const carbonTokenRef = useRef(null);
  const q45TokenRef = useRef(null);
  const vcmTokenRef = useRef(null);

  // Timer effect for carbon tokens
  useEffect(() => {
    if (timeRemaining <= 0) {
      setStatus('Complete');
      return;
    }

    const timer = setInterval(() => {
      setTimeRemaining(prev => {
        const newTime = Math.max(0, prev - 1);
        if (newTime === 0) {
          setStatus('Complete');
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining]);

  // 45Q timer effect
  useEffect(() => {
    if (!show45QTransaction || timeRemaining45Q <= 0) return;

    const timer = setInterval(() => {
      setTimeRemaining45Q(prev => {
        const newTime = Math.max(0, prev - 1);
        if (newTime === 0) {
          setStatus45Q('Complete');
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemaining45Q, show45QTransaction]);

  // VCM timer effect
  useEffect(() => {
    if (!showVCMTransaction || timeRemainingVCM <= 0) return;

    const timer = setInterval(() => {
      setTimeRemainingVCM(prev => {
        const newTime = Math.max(0, prev - 1);
        if (newTime === 0) {
          setStatusVCM('Complete');
        }
        return newTime;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, [timeRemainingVCM, showVCMTransaction]);

  const handleMintCarbonTokens = async () => {
    if (!transactionDetails || !transactionDetails.massCertificateData) {
      console.error('No transaction details available for minting');
      return;
    }

    if(mintingError) { console.error(mintingError); }

    try {
      const totalMass = transactionDetails.massCertificateData.totalMass;
      const recipientAddress = transactionDetails.recipientAddress || '0xa9E302Ac18cB1De11cA92970579D2083bB2D1D84';
      
      await mint('carbon', totalMass, recipientAddress);
      setShowTransaction(true);
      setTimeout(() => {
        carbonTokenRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    } catch (err) {
      console.error('Error minting carbon tokens:', err);
    }
  };

  const handleMint45QTokens = async () => {
    if (!transactionDetails || !transactionDetails.massCertificateData) {
      console.error('No transaction details available for minting');
      return;
    }
    
    try {
      const totalMass = transactionDetails.massCertificateData.totalMass;
      const recipientAddress = transactionDetails.recipientAddress || '0xa9E302Ac18cB1De11cA92970579D2083bB2D1D84';
      
      await mint('45q', totalMass, recipientAddress);
      setShow45QTransaction(true);
      setTimeout(() => {
        q45TokenRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    } catch (err) {
      console.error('Error minting 45Q tokens:', err);
    }
  };

  const handleMintVCMTokens = async () => {
    if (!transactionDetails || !transactionDetails.massCertificateData) {
      console.error('No transaction details available for minting');
      return;
    }
    
    try {
      const totalMass = transactionDetails.massCertificateData.totalMass;
      const recipientAddress = transactionDetails.recipientAddress;
      
      await mint('vcm', totalMass, recipientAddress);
      setShowVCMTransaction(true);
      setTimeout(() => {
        vcmTokenRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 100);
    } catch (err) {
      console.error('Error minting VCM tokens:', err);
    }
  };

  const handleGenerate8933Form = async () => {
    if (!transactionDetails) {
      console.error('No transaction details available for form generation');
      return;
    }
    
    try {
      const formData = {
        tokenAmount: transactionDetails.massCertificateData?.totalMass,
        recipientAddress: transactionDetails.recipientAddress,
        transactionHash: transactionDetails.id,
        timestamp: transactionDetails.timestamp
      };
      
      await generateForm(formData);
    } catch (err) {
      console.error('Error generating 8933 form:', err);
    }
  };

  // Create token transaction data from transaction details
  const getTokenData = () => {
    if (!transactionDetails) return null;
    
    return {
      tokensMinted: parseInt(transactionDetails.massCertificateData?.totalMass) || 0,
      recipientAddress: transactionDetails.recipientAddress || '0xa9E302Ac18cB1De11cA92970579D2083bB2D1D84',
      networkFee: 45035, // Default network fee
      transactionDate: new Date(),
      transactionHash: transactionDetails.id
    };
  };

  // IPFS link handling
  const getIpfsLink = () => {
    if (!transactionDetails || !transactionDetails.ipfsLink) {
      return '';
    }
    
    const ipfsLink = transactionDetails.ipfsLink;
    
    if (window.location.hostname === 'localhost') {
      return `http://localhost:8080/ipfs/${ipfsLink}`;
    } else {
      return `https://ipfs.io/ipfs/${ipfsLink}`;
    }
  };

  // If no transaction details and still loading, show loading screen
  if (!transactionDetails && detailsLoading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="flex flex-col items-center space-y-4">
          <RefreshCw className="h-10 w-10 text-blue-500 animate-spin" />
          <p className="text-lg text-blue-600">Loading transaction details...</p>
        </div>
      </div>
    );
  }

  // If no transaction details and not loading, show error
  if (!transactionDetails && !detailsLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-64 space-y-4">
        <div className="p-4 bg-red-50 border border-red-200 rounded-md max-w-md">
          <p className="text-red-700 text-center">Transaction details not found</p>
          <p className="text-sm text-red-600 mt-2 text-center">
            {detailsError || 'Unable to load transaction details. Please try again or select a different transaction.'}
          </p>
          <div className="flex justify-center mt-4">
            <button
              onClick={refreshTransactionDetails}
              className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors flex items-center"
            >
              <RefreshCw className="h-4 w-4 mr-2" />
              Retry
            </button>
          </div>
        </div>
      </div>
    );
  }

  const tokenData = getTokenData();
  const ipfsLink = getIpfsLink();

  return (
    <div className="space-y-4">
      {detailsError && (
        <div className="p-4 bg-red-50 border border-red-200 rounded-md flex items-start space-x-2">
          <div className="flex-1">
            <p className="text-red-700">{detailsError}</p>
            <p className="text-sm text-red-600 mt-1">
              There was an error loading some transaction details.
            </p>
          </div>
          <button
            onClick={refreshTransactionDetails}
            className="p-1.5 bg-red-100 rounded-full hover:bg-red-200"
            title="Retry loading transaction details"
          >
            <RefreshCw className="h-4 w-4 text-red-600" />
          </button>
        </div>
      )}

      {/* Reporting Data Section */}
      {ipfsLink && (
        <div className="p-2 md:p-4 bg-gray-50 rounded-md">
          <h3 className="font-medium">Reporting Data</h3>
          <a 
            href={ipfsLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-sm text-blue-600 hover:text-blue-700 break-all flex items-center"
          >
            {ipfsLink}
            <ExternalLink className="h-4 w-4 ml-1" />
          </a>
        </div>
      )}

      {/* Two Column Layout */}
      <div className="grid grid-cols-12 gap-4 items-start">
        {/* Left Column - 2/3 width */}
        <div className="col-span-8 space-y-4">
          {/* MassCertificate Data Section */}
          <div className="p-2 md:p-4 bg-gray-50 rounded-md relative">
            <h3 className="font-medium h-8">MassCertificate Data</h3>
            
            {detailsLoading && (
              <div className="absolute inset-0 bg-white/80 flex items-center justify-center">
                <div className="flex items-center">
                  <RefreshCw className="h-5 w-5 text-blue-500 animate-spin mr-2" />
                  <span className="text-blue-600">Loading transaction details...</span>
                </div>
              </div>
            )}
            
            <dl className="grid grid-cols-2 gap-2 text-sm">
              {transactionDetails?.massCertificateData?.totalMass && (
                <>
                  <dt className="text-gray-600">Total Mass:</dt>
                  <dd>{transactionDetails.massCertificateData.totalMass} tons CO<sub>2</sub></dd>
                </>
              )}
              
              {transactionDetails?.massCertificateData?.endTimestamp && (
                <>
                  <dt className="text-gray-600">End Timestamp:</dt>
                  <dd>{transactionDetails.massCertificateData.endTimestamp}</dd>
                </>
              )}

              {/* Display additional fields from API if available */}
              {transactionDetails?.massCertificateData?.verificationStatus && (
                <>
                  <dt className="text-gray-600">Verification Status:</dt>
                  <dd>{transactionDetails.massCertificateData.verificationStatus}</dd>
                </>
              )}
              
              {/* Transaction Information section integrated into MassCertificate data */}
              {transactionDetails?.id && (
                <>
                  <dt className="text-gray-600">Transaction ID:</dt>
                  <dd className="truncate">
                    <a 
                      href={`https://www.oklink.com/amoy/tx/${transactionDetails.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 hover:text-blue-700 flex items-center"
                      title={transactionDetails.id} // Show full ID on hover
                    >
                      {shortenTransactionId(transactionDetails.id)}
                      <ExternalLink className="h-3 w-3 ml-1 shrink-0" />
                    </a>
                  </dd>
                </>
              )}
              
              {transactionDetails?.timestamp && (
                <>
                  <dt className="text-gray-600">Transaction Date:</dt>
                  <dd>{new Date(transactionDetails.timestamp).toLocaleString()}</dd>
                </>
              )}
              
              {transactionDetails?.status && (
                <>
                  <dt className="text-gray-600">Transaction Status:</dt>
                  <dd className="capitalize">{transactionDetails.status}</dd>
                </>
              )}
            </dl>
          </div>

          {/* Transaction Details Sections */}
          {showTransaction && tokenData && (
            <div ref={carbonTokenRef}>
              <TransactionDetails
                title="CarbonToken Transaction Details"
                data={tokenData}
                timeRemaining={timeRemaining}
                status={status}
                tokenSymbol="CT"
                transactionHash={tokenData.transactionHash}
              />
            </div>
          )}

          {showVCMTransaction && tokenData && (
            <div ref={vcmTokenRef}>
              <TransactionDetails
                title="VCMToken Transaction Details"
                data={{...tokenData, networkFee: 52142}}
                timeRemaining={timeRemainingVCM}
                status={statusVCM}
                tokenSymbol="VCM"
                transactionHash={tokenData.transactionHash}
              />
            </div>
          )}

          {show45QTransaction && tokenData && (
            <div ref={q45TokenRef}>
              <TransactionDetails
                title="45QToken Transaction Details"
                data={{...tokenData, networkFee: 52142}}
                timeRemaining={timeRemaining45Q}
                status={status45Q}
                tokenSymbol="45Q"
                transactionHash={tokenData.transactionHash}
              />
            </div>
          )}
        </div>

        {/* Right Column - Token Actions */}
        <div className="col-span-4">
          {/* First button - Mint Carbon Tokens */}
          <div className="p-2 md:p-4 bg-gray-50 rounded-md">
            <h3 className="font-medium mb-4">Token Actions</h3>
            <button
              onClick={handleMintCarbonTokens}
              disabled={showTransaction || !token || detailsLoading || !transactionDetails}
              className={`w-full flex items-center justify-center px-4 py-3 rounded-lg transition-colors md:text-base text-xs
                ${showTransaction || !token || detailsLoading || !transactionDetails
                  ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                  : 'bg-blue-600 text-white hover:bg-blue-700'
                }`}
            >
              <Coins className="w-5 h-5 mr-1 md:mr-2" />
              Mint Carbon Tokens
            </button>
          </div>

          {/* Second and Third buttons appear after first token minted */}
          {showTransaction && (
            <>
              <div className="p-2 md:p-4 bg-gray-50 rounded-md mt-4">
                <button
                  onClick={handleMintVCMTokens}
                  disabled={timeRemaining > 0 || showVCMTransaction || !token}
                  className={`w-full flex items-center justify-center px-4 py-3 rounded-lg transition-colors md:text-base text-xs
                    ${timeRemaining > 0 || showVCMTransaction || !token
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-blue-600 text-white hover:bg-blue-700'
                    }`}
                >
                  <Coins className="w-5 h-5 mr-1 md:mr-2" />
                  Mint VCM Tokens
                </button>
              </div>
              <div className="p-2 md:p-4 bg-gray-50 rounded-md">
                <button
                  onClick={handleMint45QTokens}
                  disabled={timeRemaining > 0 || show45QTransaction || !token}
                  className={`w-full flex items-center justify-center px-4 py-3 rounded-lg transition-colors md:text-base text-xs
                    ${timeRemaining > 0 || show45QTransaction || !token
                      ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                      : 'bg-blue-600 text-white hover:bg-blue-700'
                    }`}
                >
                  <Coins className="w-5 h-5 mr-1 md:mr-2" />
                  Mint 45Q Tokens
                </button>
              </div>
            </>
          )}

          {/* Third button appears after 45Q token minted */}
          {show45QTransaction && (
            <div className="p-2 md:p-4 bg-gray-50 rounded-md mt-4">
              <button
                onClick={handleGenerate8933Form}
                disabled={timeRemaining45Q > 0 || !token}
                className={`w-full flex items-center justify-center px-4 py-3 rounded-lg transition-colors md:text-base text-xs
                  ${timeRemaining45Q > 0 || !token
                    ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                    : 'bg-green-600 text-white hover:bg-green-700'
                  }`}
              >
                <FileCheck className="w-5 h-5 mr-1 md:mr-2" />
                Generate 8933 Form
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Results;