import { useState, useEffect, useCallback } from 'react';
import { getTransactions } from '../api/services';

export const useTransactions = (token) => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);

  const fetchTransactions = useCallback(async () => {
    if (!token) {
      setError('No authentication token available');
      return;
    }

    try {
      setLoading(true);
      setError(null);
      
      const data = await getTransactions(token);
      
      if (data && Array.isArray(data.transactions)) {
        setTransactions(data.transactions);
      } else {
        console.warn('Unexpected transaction data format:', data);
        setTransactions([]);
      }
      
      setLastUpdated(new Date());
    } catch (err) {
      console.error('Error fetching transactions:', err);
      setError(err.message || 'Failed to load transactions');
    } finally {
      setLoading(false);
    }
  }, [token]);

  // Initial fetch
  useEffect(() => {
    if (token) {
      fetchTransactions();
    }
  }, [token, fetchTransactions]);

  // Periodic refresh - every 30 seconds
  useEffect(() => {
    if (!token) return;

    const intervalId = setInterval(fetchTransactions, 30000);
    
    return () => clearInterval(intervalId);
  }, [token, fetchTransactions]);

  return {
    transactions,
    loading,
    error,
    lastUpdated,
    refreshTransactions: fetchTransactions
  };
};