// src/components/Header.jsx
import React from 'react';
import { useAuth } from 'react-oidc-context';
import GreenButton from './GreenButton';

const Header = () => {
  const auth = useAuth();

  const signOutRedirect = () => {
    const clientId = "1m7k7d8a3otlavgqlcctt9agp4";
    const logoutUri = "https://demo.carbon3.tech/";
    const cognitoDomain = "https://us-east-1nyqlyvaq4.auth.us-east-1.amazoncognito.com";

    auth.removeUser();

    window.location.href = `${cognitoDomain}/logout?client_id=${clientId}&logout_uri=${encodeURIComponent(logoutUri)}`;
  };

  return (
    <header className="w-full bg-gray-800 text-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 py-2 flex justify-between items-center">
        {/* Site Name */}
        <div className="text-xl font-bold">
          Carbon Tokens
        </div>

        {/* Authentication Section */}
        <div>
          {auth.isAuthenticated ? (
            <div className="flex items-center space-x-4">
              <span>{auth.user?.profile.email}</span>
              <GreenButton onClick={() => signOutRedirect()}>
                Sign Out
              </GreenButton>
            </div>
          ) : (
            <GreenButton onClick={() => auth.signinRedirect()}>
              Login
            </GreenButton>
          )}
        </div>
      </div>
    </header>
  );
};

export {Header};