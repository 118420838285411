import React from 'react';

const GreenButton = ({ onClick, children }) => (
    <button
        onClick={onClick}
        className="rounded-md bg-green-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600"
    >
        {children}
    </button>
);

export default GreenButton;