import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';

const AppRouter = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/carbon-tokens" element={<Navigate to="/carbon-tokens/data" replace />} />
        <Route path="/carbon-tokens/:step" element={<App />} />
        {/* Add a dedicated route for results with transaction parameter */}
        <Route path="/carbon-tokens/results" element={<App />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;